import React from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { mediaQuery } from 'utils/style'

const Container = styled.div`
  && {
    width: 100%;
    max-width: unset;
    padding: 0;
    margin: 0;
    ${mediaQuery()}
  }
`

const Slope = styled.div`
  border-bottom: 6.54vw solid white;
  border-left: 100vw solid transparent;
  margin-top: -6.54vw;
  z-index: 101;
  position: relative;
  margin-bottom: calc(-6.54vw - 5px);
  ${({ theme }) => (theme.slopes ? '' : 'display: none;')}
`

const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const NavigationContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 540px;
  ${mediaQuery(true)}
  display:flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 60px;
`

const DotContainer = styled.div``

const Dot = styled.div`
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
  border-radius: 15px;
  border 2px solid;
  width: 15px;
  height: 15px;
  margin:10px;`

const Dots = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-bottom: 64px;
  ${mediaQuery()({
    media: device => (device === 'tablet' ? 'margin-bottom: 32px;' : '')
  })}
  > ${DotContainer} {
    :nth-child(${({ current }) => current + 1}) {
      > ${Dot} {
        background-color: ${({ theme }) => theme.colors.blue.color};
      }
    }
  }
`

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mediaQuery()};
`
const Title = styled.div`
  > div {
    color: white;
    text-shadow: 2px 2px black;
    font-size: 50px;
    font-weight: bold;
    :nth-child(even) {
      margin-left: 0.5em;
    }
  }
`

export default function StoryblokCarousel ({ data, next: nextComponent }) {
  const showSlope =
    nextComponent === 'StoryblokTiles'
    || nextComponent === 'StoryblokDownloads'
    || nextComponent === 'StoryblokLocator'
  return (
    <Container
      media={device =>
        (device === 'tablet'
          ? 'height: 320px;'
          : device === 'mobile'
            ? 'display: none;'
            : 'height: 540px;')
      }
    >
      <Carousel
        media={() =>
          `width: 100%;
          ${
            showSlope
              ? 'height: calc(100% + 6.54vw); marginBottom: -6.54vw'
              : 'height: 100%;'
          }`
        }
        autoplay
        infinite
        hideBackside
        pagination
        navigation={({
          current, prev, next, specific
        }) =>
          data.slides.length > 1 && (
            <Navigation>
              <NavigationContent
                {...showSlope && {
                  media: device =>
                    (device === 'tablet' ? 'height: 320px;' : '')
                }}
              >
                <ChevronLeft
                  style={{
                    fontSize: 80,
                    filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px)'
                  }}
                  onClick={prev}
                />
                <Dots current={current}>
                  {data.slides.map((slide, idx) => (
                    <DotContainer key={slide.id} onClick={specific(idx)}>
                      <Dot />
                    </DotContainer>
                  ))}
                </Dots>
                <ChevronRight
                  style={{
                    fontSize: 80,
                    filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px)'
                  }}
                  onClick={next}
                />
              </NavigationContent>
            </Navigation>
          )
        }
      >
        {data.slides.map(
          ({
            id,
            image,
            title1,
            title2,
            imageAlt,
            imageTitle,
            imageDescription
          }) => (
            <Image
              key={id}
              background
              src={image}
              alt={imageAlt}
              title={imageTitle}
              description={imageDescription}
            >
              <TitleContainer
                {...showSlope && {
                  media: device =>
                    (device === 'tablet'
                      ? 'height: 320px;'
                      : device === 'mobile'
                        ? 'display: none;'
                        : '')
                }}
              >
                <Title>
                  <div>{title1}</div>
                  <div>{title2}</div>
                </Title>
              </TitleContainer>
            </Image>
          )
        )}
      </Carousel>
      {showSlope && <Slope />}
    </Container>
  )
}
